import Contact from "../Components/Contact";
import Footer from "../Components/Footer";

function Contact1(params) {
  return (
    <>
      <Contact />
      <Footer />
    </>
  );
}

export default Contact1;
