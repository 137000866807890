import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useScroll, useSpring } from "framer-motion";

const About = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <Container className="about">
      {/* <motion.div
        style={{ scaleX: scrollYProgress }}
        className="progress-bar-animate"
      /> */}
      <Row>
        <Col xs={12} lg={12}>
          <div className="upper">
            <h1>Biz Kimiz</h1>

            <p>
              Dijital dünyada büyüme ve başarı hedefleyen kişiler ve işletmeler
              için kapsamlı çözümler sunan bir dijital hizmetler bireysel
              girişim ortaklığıyız. Misyonumuz, dijital varlıkları üretmek
              güçlendirmek, daha geniş kitlelere ulaşmalarını sağlamak ve
              işlerini daha verimli bir şekilde yönetmelerine yardımcı olmaktır.
            </p>
          </div>

          <div className="right">
            <h1>Peki Nasıl</h1>
            <p>
              Neden internete ve sosyal medyaya önem vermeliyim? Ben küçük bir
              işletme sahibiyim, örneğin mütevazi bir kafem var ve harika
              yemekler yapıyorum; çaylarım her zaman taze. Ancak yeterince
              müşteriye ulaşamıyorum ya da rekabet nedeniyle son zamanlarda
              müşteri kaybı yaşıyorum. Ürünlerim çok iyi, ancak gerekli talebi
              görmüyorlar. En iyi dondurmayı yapmanız değil, Google'ın en iyi
              dondurma önerileri arasında bulunmanız sizi en iyi dondurmacı
              yapar.
            </p>
            <p>
              Nedeni çok açık: Telefonumuz ve sosyal medya hesaplarımızla
              yaşıyoruz artık. Adres sormuyoruz, telefonumuzdan konuma
              bakıyoruz. Telefonumuza soruyoruz, en iyi nerede yemek yiyebilirim
              diye. İnternette yaşıyoruz; en büyük bilgi kaynağımız orası,
              tavsiyeleri Google'dan ve Instagram'dan alıyoruz.
            </p>
            <p>
              Muhtemelen bir işletme sahibi müşterileri ile sürekli etkileşim
              içinde olmak ister, isteklerini öğrenmek ya da potansiyel
              müşterilerinin taleplerini öğrenmek ister. Bu talepler internet ve
              sosyal medya nedeniyle oluştuğu için çözümü de bunlardan
              faydalanmak. Örneğin, Instagram'da işletmenin düzenli
              gönderilerini paylaşmak, müşterilerden gelen mesajlara cevap
              vermek müşteri etkileşimi için bir çözüm.
            </p>
            <p>
              Dahası, Google reklamları ile ulaşmak istenen müşteri kitlesinin
              adresine, örneğin semtine hatta sokağına kadar özelleştirilebilen
              reklamlar vermek. Bunlar sorunun bir kısmını çözer, ancak asla
              yeterli değildir. Diyelim ki bütçe ayırdınız, Instagram ve Google
              reklamı verdiniz; fakat ne kadar verimli oldu, ölçebildiniz mi?
              İsterseniz "Neler Yapıyoruz" kısmında buna cevap verelim.
            </p>
          </div>
          <div className="left">
            <h1>Neler Yapıyoruz</h1>
            <p>
              İşletmenizin bir Instagram hesabı var ve arada sırada paylaşım
              yapıyorsunuz. Reklam için bütçe ayırdınız, hatta Google İşletme
              hesabınızda gelen mesajları cevaplıyorsunuz. Ancak ayırdığınız
              bütçenin ne kadar verimli harcandığını, kaç müşterinin etkileşimde
              bulunduğunu ve web sitenizi veya Instagram paylaşımlarınızı
              gördükten sonra ziyaret eden kişi sayısını ölçtünüz mü?
              Yurtdışından belirli bir ülkeden ürünlerinizin görüntülendiğini
              biliyor musunuz? Google reklam verirken ulaşmak istediğiniz şehre,
              ilçeye, hatta sokağa kadar ulaşabildiğinizi biliyor musunuz?
              Birden fazla reklam verip bu reklamları karşılaştırdınız mı?
            </p>
            <p>
              Eğer bu sorulara cevap veremediyseniz veya bu konularda ölçümler
              yapmadıysanız, işletmenizin potansiyelini tam olarak
              değerlendiremiyor olabilirsiniz. Bu noktada, işletmenizi daha
              etkili bir şekilde yönetmek için size nasıl yardımcı
              olabileceğimizi özetleyeyim:
            </p>
            <p>Google İşletme hesabınızı açıyor ve yönetiyoruz.</p>
            <p>Google reklamlarınızı profesyonel olarak hazırlıyoruz.</p>
            <p>Web sayfanızı düzenleyip optimize ediyoruz.</p>
            <p>
              Instagram, Facebook, Twitter gibi hesaplarınızı etkili bir şekilde
              yönetiyoruz.
            </p>
            <p>
              Müşterilerinizin tüm hesaplarınızdaki mesajları düzenli bir
              şekilde cevaplıyoruz.
            </p>
            <p>Mevcut müşteri e-maillerinize düzenli gönderimler yapıyoruz.</p>
            <p>
              Eğer yurtdışına açılmak istiyorsanız, Amazon yurtdışı hesabınızı
              açıyor ve istenirse yönetiyoruz.
            </p>
            <p>
              Tüm bu süreçleri tek bir platformda izleyerek aylık ve haftalık
              raporlar hazırlıyor ve size sunuyoruz. Örneğin, web sitenizin kaç
              kişi ziyaret ettiğini, hangi şehir veya ülkeden bağlandığını,
              ulaştıkları yolları (Google, Instagram, Facebook vs.) ve
              sayfalarınıza ulaşan kişilerin demografik bilgilerini
              ölçümlüyoruz.
            </p>
            <p>
              Son olarak, tüm bu işleri yürütmekten ziyade, baştan sona tek bir
              noktadan çözüm sunarak sizin için daha etkili bir yönetim
              sağlıyoruz.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
