import About from "../Components/About";
import Footer from "../Components/Footer";

function About1(params) {
  return (
    <>
      <About />
      <Footer />
    </>
  );
}

export default About1;
