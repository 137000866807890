import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button, Col, Row, Form } from "react-bootstrap";


const Contact = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <motion.div
      className="contact-container"
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
    >
      <Row>
        <Col xs={12} md={6}>
          <h1 className="message">Mesaj Gönder</h1>
          <div className="contact-form">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="message">Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Lütfen geçerli bir email adresi girin.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="message">Mesaj</Form.Label>
                <Form.Control as="textarea" rows={3} required />
                <Form.Control.Feedback type="invalid">
                  Lütfen bir mesaj girin.
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit">
                Gönder
              </Button>{" "}
            </Form>
          </div>
        </Col>

        <Col xs={12} md={6}>
          <div className="contact-data">
            <h1 className="message1">İletişim</h1>
           
           
            <h3 className="mail">Kaan Bayram </h3>
            <p className="mail">+90 541 120 19 49 </p>
            <h2 className="mail">reconceptx@reconceptx.com</h2>
          </div>
        </Col>

        
      </Row>
    </motion.div>
    
  );
};

export default Contact;
