import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.css";
import Header from "./Components/Header";
import About from "./pages/About1";
import Contact from "./pages/Contact1";
import Home from "./pages/Home1";
import Projects from "./pages/Projects1";

import { Container } from "react-bootstrap";
import Footer from "./Components/Footer";
// import "bootstrap/dist/css/bootstrap.min.css";







function App() {
  const location = useLocation();
  return (
    <>
      <Header />
      <Container fluid>
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/hizmetlerimiz" element={<Projects />} />
            {/* <Route path="/services" element={<Services />} /> */}
            <Route path="/hakkımızda" element={<About />} />
            <Route path="/iletişim" element={<Contact />} />
            <Route path="/" element={<Footer />} />
          </Routes>
        </AnimatePresence>
      </Container>
      <Footer />
    </>
  );
}

export default App;
