import Projects from "../Components/Projects";
import Footer from "../Components/Footer";

function Projects1(params) {
  return (
    <>
      <Projects />
      <Footer />
    </>
  );
}

export default Projects1;
