import Home from "../Components/Home";
import Footer from "../Components/Footer";
import Projects from '../Components/Projects'


function Home1(params) {
  return (
    <>
      <Home />
      <Projects />

      <Footer />
    </>
  );
}

export default Home1;
