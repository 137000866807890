



import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { FaFacebook, FaInstagram } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  const closeMenu = () => setExpanded(false);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      fixed="top"
      expanded={expanded}
    >
      <Navbar.Brand as={Link} to="/" onClick={closeMenu}>
        <p className="brand"> ReconceptX </p>
      </Navbar.Brand>
    
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={() => setExpanded(!expanded)}
      >
        Menü
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav" onSelect={closeMenu}>
        <div className="nav-left">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/" onClick={closeMenu}>
              Anasayfa
            </Nav.Link>
            <Nav.Link as={Link} to="/hizmetlerimiz" onClick={closeMenu}>
              Hizmetlerimiz
            </Nav.Link>
            <Nav.Link as={Link} to="/hakkımızda" onClick={closeMenu}>
              Hakkımızda
            </Nav.Link>
            <Nav.Link as={Link} to="/iletişim" onClick={closeMenu}>
              İletişim
            </Nav.Link>
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
