import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


const Footer = () => {
  return (
    <div className="footer-container">
      <Container className="footer">
        <Row>
          <Col xs={12} md={6} className="text-md">
            <p className="copyright-text">
              Copyright © 2023 ReconceptX 
            </p>
          </Col>
          <Col xs={12} md={6}>
            <div className="social-links">
              <a
                href="https://www.facebook.com/reconceptf/"
                target="_blank"
                rel="noopener noreferrer"
                className=" face-nav-link"
              >
                <FaFacebook />
              </a>
              <a
                href="https://twitter.com/ReconceptX"
                target="_blank"
                rel="noopener noreferrer"
                className=" twitter-nav-link"
              >
                <FaXTwitter />
              </a>
              <a
                href="https://www.instagram.com/reconceptx/"
                target="_blank"
                rel="noopener noreferrer"
                className=" insta-nav-link"
              >
                <FaInstagram />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
