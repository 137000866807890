import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { motion } from "framer-motion";

import { RiComputerLine } from "react-icons/ri";
import { MdOutlineSocialDistance } from "react-icons/md";
import { SiGoogleads } from "react-icons/si";
import { FaBusinessTime } from "react-icons/fa6";
import { SiGoogledatastudio } from "react-icons/si";
import { AiFillAmazonCircle } from "react-icons/ai";

const Projects = () => {
  const [expandedId, setExpandedId] = useState(null);

  const toggleExpansion = (id) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };

  const projectList = [
    {
      id: 1,
      title: "Web Tasarım ve Mobil Uygulama Geliştirme",
      content:
        "Kişisel veya işletme ihtiyaçlarına özel web siteleri ve mobil uygulamalar geliştirmek için profesyonel hizmetler sunuyoruz. Web tasarımında ve mobil uygulama geliştirmede uzman ekibimiz, kullanıcı dostu, etkileyici ve işlevsel çözümler oluşturmak için en son teknolojileri kullanmaktadır.",
      icon: <RiComputerLine />,
    },
    {
      id: 2,
      title: "Sosyal Medya Yönetimi",
      content:
        "Sosyal medya platformları, markanızın geniş kitlelere ulaşmasını ve etkileşimde bulunmasını sağlayan güçlü araçlardır. Sosyal medya yönetimi hizmetimiz sayesinde, Instagram, Facebook ve Twitter gibi önde gelen platformlarda güçlü bir çevrimiçi varlık oluşturmanıza yardımcı oluyoruz.",
      icon: <MdOutlineSocialDistance />,
    },
    {
      id: 3,
      title: "Dijital Dünya ve Google Sıralamaları",
      content:
        "Günümüzde iş dünyası dijitalleşiyor ve işletme sahipleri için dijital dünyada var olmak artık bir lüks değil, bir zorunluluk haline geldi. Dijital dünyada rakiplerinizle rekabet etmek ve yeni müşterilere ulaşmak, işletmenizin büyümesi ve gelişmesi için hayati öneme sahiptir. İşte burada, Google aramalarında en üst sıralarda görünerek, işletmenizin karını artırmanıza yardımcı olabiliriz.",
      icon: <FaBusinessTime />,
    },
    {
      id: 4,
      title: "Google Ads Reklam Yönetimi",
      content:
        "Google Ads, potansiyel müşterilere ulaşmanın hızlı ve etkili bir yolunu sunar. Reklam bütçenizi optimize etmek, anahtar kelime stratejilerinizi oluşturmak ve reklam kampanyalarınızı yönetmek için uzman bir elden yardım alarak, işletmenizin dijital varlığını güçlendirebilir ve hedef kitlenize ulaşabilirsiniz. Google Ads reklam yönetimi ile işletmenizi çevrimiçi platformlarda daha görünür kılın ve büyümeyi hedefleyin.",
      icon: <SiGoogleads />,
    },
    {
      id: 5,
      title: "Veri Analizi Hizmetleri",
      content:
        "Veri, işletmenizin altın madeni olabilir, ancak doğru bir şekilde yönetilmezse değersiz olabilir. İşte bu noktada, veri analizi ve hizmetleri devreye girer. Verilerinizi anlamak, işletmenizin potansiyelini açığa çıkarmanın anahtarıdır. Veri analizi hizmetlerimiz, karmaşık veri setlerini çözümlemek, eğilimleri belirlemek ve stratejik kararlarınıza ışık tutmak için size yardımcı olur. Verilerinizi optimize ederek, rekabet avantajı elde edin ve işletmenizi daha verimli bir şekilde yönetin.",
      icon: <SiGoogledatastudio />,
    },
    {
      id: 6,
      title: "E-Ticaret Platformları",
      content:
        "Sadece Trendyol ve benzeri yurt içi platformlarla sınırlı kalmıyor, aynı zamanda uluslararası devlerin dünyasına adım atarken bile yanınızdayız. Stratejik rehberlik sağlamanın yanı sıra, e-ticaret platformlarını kurma, ürün kataloglarını oluşturma ve yaratıcı dijital pazarlama kampanyalarını tasarlama konularında size kapsamlı bir teknik destek sunuyoruz. İşletmenizin büyümesini hedefliyor ve global düzeyde başarıya ulaşmanızı destekliyoruz. Dijital ticaretin heyecan verici dünyasında, işletmenizin büyümesine rehberlik etmek için yanınızdayız.",
      icon: <AiFillAmazonCircle />,
    },
  ];

  return (
    <motion.div
      animate={{
        scale: [2, 1],
        rotate: [90, 0],
      }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
      }}
      className="hizmetlerimiz"
    >
      <h1>Hizmetlerimiz</h1>
      <div className="wrapper">
        {projectList.map((item) => {
          const isExpanded = expandedId === item.id;

          return (
            <motion.div
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.5 },
              }}
              key={item.id}
              className={`card-wrapper ${isExpanded ? "expanded" : ""}`}
            >
              <Card>
                <div className="icon">{item.icon}</div>
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>
                    {isExpanded
                      ? item.content
                      : `${item.content.slice(0, 100)}...`}
                    <span
                      className={`read-more-less ${
                        isExpanded ? "read-less" : "read-more"
                      }`}
                      onClick={() => toggleExpansion(item.id)}
                    >
                      {isExpanded ? "Read Less" : "Read More"}
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default Projects;
