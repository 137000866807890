

// import React from "react";
// import { Container, Row, Col, Image } from "react-bootstrap";
// import { motion } from "framer-motion";
// import Logo from "../picture/4.png";
// import { RiComputerLine } from "react-icons/ri";
// import { ImConnection } from "react-icons/im";
// import { FcGoogle } from "react-icons/fc";
// import { GiVibratingSmartphone } from "react-icons/gi";
// import { SiAmazonaws } from "react-icons/si";
// import { GrAmazon } from "react-icons/gr";



// const Home = () => {
//   const logoVariants = {
//     initial: {
//       rotate: -360,
//     },
//     animate: {
//       rotate: 0,
//       transition: { duration: 1.5 },
//     },
//   };

//   const h3Variants = {
//     initial: {
//       opacity: 0,
//     },
//     animate: {
//       opacity: 1,
//       transition: { duration: 13.5, yoyo: fetch },
//     },
//   };

//   return (
//     <Container fluid className="home ">
//       <div className="anasayfa">
//         <motion.div className="content">
//           <Container fluid className="py-1">
//             <Row>
//               <Col md={8} className="text-container">
//                 <motion.div
//                   initial={{ x: -90, opacity: 0 }}
//                   animate={{ x: 0, opacity: 1 }}
//                   transition={{ duration: 1.5 }}
//                 >
//                   <h1 className="main-text">
                
//             Neler yapıyoruz ...
                 
                  
//                   </h1>

//                   <div className="icon-and-text">
//                     <RiComputerLine className="icon" />
//                     <h2>Sizi en etkili şekilde internete taşıyoruz</h2>
//                   </div>

//                   <div className="icon2-and-text2">
//                     <ImConnection className="icon2" />
//                     <p>Web sitesinizi ve mobil uygulamalarınızı yapıyor</p>
//                   </div>

//                   <div className="icon3-and-text3">
//                     <FcGoogle className="icon3" />
//                     <p>Sitenizi Google'da en üst sıralara taşıyor</p>
//                   </div>

//                   <div className="icon4-and-text4">
//                     <GiVibratingSmartphone className="icon4" />
//                     <p>Sosyal medya yönetiminizi üstleniyoruz</p>
//                   </div>

//                   <div className="icon5-and-text5">
//                     <SiAmazonaws className="icon5" />
//                     <h2>E-Ticari işletmelerinizi dijitalde yönetiyoruz</h2>
//                   </div>

//                   <div className="icon6-and-text6">
//                     <GrAmazon className="icon6" />
//                     <p>
//                       Amazon ve diğer platformlarda yurtdışı ve yurtiçi
//                       mağazalarınızın açılışını ve işletmesini üstleniyoruz
//                     </p>
//                   </div>
//                 </motion.div>
//               </Col>
//               <Col md={4} className="image-container">
//                 <motion.div
//                   variants={logoVariants}
//                   initial="initial"
//                   animate="animate"
//                 >
//                   <Image src={Logo} alt="Logo" fluid />
//                   <motion.h3 variants={h3Variants}>
//                     Uçtan Uca Çözüm...
//                   </motion.h3>
//                 </motion.div>
//               </Col>
//             </Row>
//           </Container>
//         </motion.div>
//       </div>
//     </Container>
//   );
// };

// export default Home;





import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { motion } from "framer-motion";
import Logo from "../picture/4.png";
import { RiComputerLine } from "react-icons/ri";
import { ImConnection } from "react-icons/im";
import { FcGoogle } from "react-icons/fc";
import { GiVibratingSmartphone } from "react-icons/gi";
import { SiAmazonaws } from "react-icons/si";
import { GrAmazon } from "react-icons/gr";

const Home = () => {
  const logoVariants = {
    initial: {
      rotate: -360,
    },
    animate: {
      rotate: 0,
      transition: { duration: 1.5 },
    },
  };

  const h3Variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { duration: 13.5, yoyo: fetch },
    },
  };

  return (
    <Container fluid className="home">
      <div className="anasayfa">
        <motion.div className="content">
          <Container fluid className=" py-10">
            <Row>
              <Col md={4} className="image-container order-md-first">
                <motion.div
                  variants={logoVariants}
                  initial="initial"
                  animate="animate"
                >
                  <Image src={Logo} alt="Logo" fluid className="logo" />
                 
                  <motion.h3 variants={h3Variants} className="uç">
                    Uçtan Uca Çözüm...
                  </motion.h3>
                </motion.div>
              </Col>
              <Col md={8} className="text-container">
                <motion.div
                  initial={{ x: -90, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1.5 }}
                >
                  <h1 className="main-text">Neler yapıyoruz ...</h1>

                  <div className="icon-and-text">
                    <RiComputerLine className="icon" />
                    <h2>Sizi en etkili şekilde internete taşıyoruz</h2>
                  </div>

                  <div className="icon2-and-text2">
                    <ImConnection className="icon2" />
                    <p>Web sitesinizi ve mobil uygulamalarınızı yapıyor</p>
                  </div>

                  <div className="icon3-and-text3">
                    <FcGoogle className="icon3" />
                    <p>Sitenizi Google'da en üst sıralara taşıyor</p>
                  </div>

                  <div className="icon4-and-text4">
                    <GiVibratingSmartphone className="icon4" />
                    <p>Sosyal medya yönetiminizi üstleniyoruz</p>
                  </div>

                  <div className="icon5-and-text5">
                    <SiAmazonaws className="icon5" />
                    <h2>E-Ticari işletmelerinizi dijitalde yönetiyoruz</h2>
                  </div>

                  <div className="icon6-and-text6">
                    <GrAmazon className="icon6" />
                    <p>
                      Amazon ve diğer platformlarda yurtdışı ve yurtiçi
                      mağazalarınızın açılışını ve işletmesini üstleniyoruz
                    </p>
                  </div>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      </div>
    </Container>
  );
};

export default Home;


